@use 'styles/variables';
@use 'styles/functions';

.container {
  max-width: variables.$xl;
  margin: auto;
}

.main {
  margin-top: functions.spacing(9);
}
