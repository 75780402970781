@use 'styles/functions';
@use 'styles/variables';

.header {
  /* flex justify-between items-center border-b-2 border-gray-100 py-2 px-4 */
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: space-between;
  padding: functions.spacing(1);
  border-bottom: 1px solid #e6e6e6;
  height: functions.spacing(9);
}

.right {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: flex-end;
}

.right.desktop {
  display: none;
}

@media (min-width: variables.$sm) {
  .left {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: flex-start;
  }

  .right.desktop {
    display: flex;
    gap: functions.spacing(4);
  }

  .right.mobile {
    display: none;
  }

  a.item {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }

  a.active {
    text-decoration: underline;
  }

  .mobileNav {
    display: none;
  }
}

.nav {
  overflow: hidden;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  max-width: variables.$xl;
}

.inner {
  height: 100vh;
  overflow-y: scroll;
}

.fixedHeader {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  max-width: variables.$xl;
  background-color: variables.$white;
}

.mobileNav {
  background-color: variables.$light-grey;
  font-size: 1.25rem;
  ul {
    margin: unset;
    padding-top: functions.spacing(3);
    padding-bottom: functions.spacing(3);
    padding-right: functions.spacing(4);
    list-style-type: none;
  }
  li:not(:last-child) {
    margin-bottom: functions.spacing(1);
    padding-bottom: functions.spacing(1);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: variables.$grey;
  }
  li {
    a {
      display: block;
      padding-top: functions.spacing(1);
      padding-bottom: functions.spacing(1);
    }
    a.active {
      font-weight: bold;
    }
  }
}
