@use 'styles/variables';
@use 'styles/functions';

.button {
  align-self: center;
  background-color: variables.$primary-5; // pink-4
  color: variables.$white;
  font-weight: 600;
  padding-left: functions.spacing(2);
  padding-right: functions.spacing(2);
  padding-top: functions.spacing(1.5);
  padding-bottom: functions.spacing(1.5);
  border-radius: functions.spacing(1);
  border: none;
  box-shadow: 1px 2px 2px rgb(233, 233, 233);
  &:hover {
    background-color: variables.$primary-4; // pink 5
    box-shadow: 1px 2px 6px #59738f;
  }
}
.pink {
  background-color: variables.$pink-1;
  color: variables.$black;
  &:hover {
    background-color: variables.$pink-2;
  }
}
