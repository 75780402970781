@use 'styles/variables';
@use 'styles/functions';
@import '../../node_modules/modern-normalize/modern-normalize.css';
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  // https://simonhearne.com/2021/layout-shifts-webfonts/
  // optional - hide text for up to 100ms, then only use the web font if it is available - never swapping
  font-display: swap;
  src: url('/fonts/poppins-latin-400.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/poppins-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/poppins-latin-700.woff') format('woff');
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  // Without this it is 1.15 for some reason and causes issues
  line-height: normal;
  background-color: variables.$white;
  color: variables.$black;
  scroll-padding-top: functions.spacing(10);
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  cursor: pointer;
}
h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}
h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}
h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}
h4 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
}
h5 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
h6 {
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
}
p {
  font-size: 1.25rem;
  margin: 0;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  padding-left: functions.spacing(2);
  padding-right: functions.spacing(2);
  @media (min-width: variables.$sm) {
    padding-left: functions.spacing(4);
    padding-right: functions.spacing(4);
  }
  padding-bottom: functions.spacing(2);
}
section:not(:first-child) {
  padding-top: functions.spacing(2);
}

footer {
  width: 100%;
  min-height: 200px;
  background-color: variables.$light-grey;
  padding: functions.spacing(2);
  @media (min-width: variables.$sm) {
    padding: functions.spacing(4);
  }
}
