@use 'styles/functions';
@use 'styles/variables';

.menuBtn {
  //background-color: variables.$primary-0;
  //border-radius: functions.spacing(1);
  background-color: transparent;
  display: block;
  border: none;
  padding-left: functions.spacing(2);
  padding-right: functions.spacing(2);

  // remove lightblue highlight on tap in mobile view
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  svg {
    width: functions.spacing(4);
    height: functions.spacing(4);
    color: variables.$black;
  }
}
